<template>
  <div class="main finger border-bg">
    <div class="border-tit">当前位置 > 成绩查询 > 《考试名称》成绩查询</div>
    <div class="score-login">
      <div class="login-tit">《考试名称》成绩查询</div>
      <div class="login-form">
        <div class="flex login-form-item">
          <div class="item-tit">请输入准考证号：</div>
          <input placeholder="请输入准考证号" v-model="card" />
          <span>*</span>
        </div>
        <div class="flex login-form-item">
          <div class="item-tit">请输入考生号：</div>
          <input placeholder="请输入考生号：" v-model="card" />
          <span>*</span>
        </div>
        <div class="flex login-form-item">
          <div class="item-tit">请输入证件号后6位：</div>
          <input placeholder="请输入证件号后6位" v-model="card" />
          <span>*</span>
        </div>
        <div class="flex login-form-item">
          <div class="item-tit">请输入验证码：</div>
          <input placeholder="请输入验证码" v-model="card" />
          <span>*</span>
        </div>
        <!-- 按钮 -->
        <div class="form-btn flex between">
            <div class="pointer" @click="toSearch">查询</div>
            <div class="pointer">重置</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      card: "",
    };
  },
  mounted() {
    this.$bus.emit("navS", 5);
  },
  methods:{
      toSearch(){
          this.$router.push('/scoreResult')
      },
  }
};
</script>
<style lang="less" scoped>
.finger {
  margin-top: 20px;
}
.score-login {
  width: 80%;
  margin: 30px auto 40px;
  background: linear-gradient(to bottom, #d2f4ff, #ffffff);
  border: dashed 1px rgb(88, 87, 87);
  .login-tit {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    line-height: 4;
    border-bottom: solid 3px rgb(151, 150, 150);
    width: 80%;
    margin: 0 auto;
  }
  .login-form {
    text-align: center;
    margin: 0 auto;
    font-size: 18px;
    padding: 20px 0 20px;
    .login-form-item {
      justify-content: center;
      align-items: center;
      line-height: 3;
      input {
        padding: 5px;
      }
      span {
        color: #ff1919;
        margin-left: 5px;
      }
      .item-tit {
          width: 20%;
          text-align: right;
      }
    }
    .form-btn{
        width: 25%;
        margin: 30px auto 20px;
        div{
            background: linear-gradient(to bottom,#D6EDFE,#79C4FB);
            color: #005791;
            padding: 8px 25px;
            border: solid 1px;
        }
    }
  }
}
</style>